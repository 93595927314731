import { POSITRON } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';

export const initialState = {
  viewState: {
    latitude: 54.97384334115,
    longitude: -1.9116801053802088,
    zoom: 5,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: POSITRON,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
    accessToken:
      'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfN3hoZnd5bWwiLCJqdGkiOiJhNzU1ZmViYSJ9.NGyVuW88nvUOXJ0ky-uLBv8PLRoJMlB6DquY-NQELvk',
  },
  googleApiKey: '', // only required when using a Google Basemap,
  // oauth: {
  //     domain: 'auth.carto.com',
  //     // Type here your application client id
  //     clientId: 'KMw92uFdShogxkKulaaj1YzqUwd4EZlr',
  //     scopes: [
  //         'read:current_user',
  //         'update:current_user',
  //         'read:connections',
  //         'write:connections',
  //         'read:maps',
  //         'write:maps',
  //         'read:account',
  //         'admin:account',
  //     ],
  //     audience: 'carto-cloud-native-api',
  //     authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  // },
};
